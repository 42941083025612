import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { UilAngleLeft, UilPlus, UilMinus } from '@iconscout/react-unicons'

import { SET_CART } from 'store/actions'
import { useTelegram } from "./useTelegram"
import { formatMoney } from './helpers';

function Cart() {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const { login, posId } = useParams()
	const { tg } = useTelegram()

	const reduxCart = useSelector(state => state.cart)
	const reduxInformation = useSelector(state => state.information)

	const [cart, setCart] = useState([]);
	const [changeable, setChangeable] = useState(false);

	function handleChangeQuantity(product, type) {
		var cartCopy = [...cart]
		var index = cartCopy.findIndex(item => item.id === product.id)

		if (type === 'plus') {
			cartCopy[index]['quantity'] += 1
		}

		if (type === 'minus') {
			if (cartCopy[index]['quantity'] === 1) {
				cartCopy.splice(index, 1)
			} else {
				cartCopy[index]['quantity'] -= 1
			}
		}

		setCart(cartCopy)
		dispatch(SET_CART(cartCopy))
		
		if (cartCopy.length === 0) {
			navigate(`/menu/${login}/${posId}`)
		}
	}

	function getTotalPrice(items = []) {
		var totalPrice = 0
		for (let i = 0; i < items.length; i++) {
			totalPrice += items[i]['quantity'] * items[i]['sale_price']
		}
		return formatMoney(totalPrice)
	}

	function getProductQuantity(id) {
		return reduxCart.find(item => item.id === id)['quantity']
	}

	function clearCart() {
		dispatch(SET_CART([]))
		navigate(`/menu/${login}/${posId}`)
	}

	function handleTgButtonClick() {
		navigate(`/order/${login}/${posId}`)
	}

	useEffect(() => {
		tg.onEvent('mainButtonClicked', handleTgButtonClick)
		return () => {
			tg.offEvent('mainButtonClicked', handleTgButtonClick)
		}
	}, [handleTgButtonClick]) // eslint-disable-line react-hooks/exhaustive-deps

	useEffect(() => {
		if (reduxCart.length) {
			setCart(reduxCart)

			tg.MainButton.show();
			tg.MainButton.setParams({
				text: `${t('continue')}`,
				color: reduxInformation?.theme?.button_bg_color_plus,
				textColor: reduxInformation?.theme?.button_text_color,
			})
		}
		if (!reduxCart.length) {
			tg.MainButton.hide();
		}
	}, []) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<div className="tg-body">
			<div className="tg-body-inner">
				<div className="tg-header">
					<div className="tg-header-back" onClick={() => navigate(-1)}>
						<UilAngleLeft></UilAngleLeft>
						{t('back')}
					</div>
					<div className="tg-header-title">{t('cart')}</div>
					<div className="tg-header-end"
						style={{ 'color': '#f46a6a' }}
						onClick={() => clearCart()}>
						{t('clear')}
					</div>
				</div>

				<div className="d-flex justify-content-between mb-3">
					<div className="tg-cart-title">{t('your_order')}</div>
					<div className="vertical-center"
						style={{ 'color': reduxInformation?.theme?.button_bg_color_plus }}
						onClick={() => setChangeable(!changeable)}>
						{t('change')}
					</div>
				</div>

				{reduxCart?.map((item, index) => (
					<div className="tg-cart-wrapper" key={index}>
						<div className="d-flex" style={{ 'width': '70%' }}>
							<div className="tg-cart-img-wrapper">
								<div style={{ 'width': '97px' }}>
									{item.image ?
										<img src={`https://backend.mison.uz/uploads/products/${item.image}`}
											alt={item.image}
											className="tg-product-item-img" />
										:
										<img src="https://backend.mison.uz/no_image.png" alt="no_image"
											className="tg-product-item-img" />
									}
								</div>
								<span className="tg-products-item-quantity"
									style={{
										'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
										'color': reduxInformation?.theme?.button_text_color
									}}>
									{getProductQuantity(item.id)}
								</span>
							</div>
							<div className="tg-cart-item-name">
								{item.name}
							</div>
						</div>
						<div className="tg-cart-price-wrapper" style={{ 'width': '30%' }}>
							<div className="tg-cart-item-price">
								{formatMoney(item.sale_price * item.quantity)} {t('sum')}
							</div>
							{changeable &&
								<div className="d-flex" style={{ 'gap': '10px' }}>
									<button
										className="tg-products-button-add2 ripple"
										style={{
											'backgroundColor': reduxInformation.theme.button_bg_color_minus,
											'color': reduxInformation.theme.button_minus_text_color
										}}
										onClick={() => handleChangeQuantity(item, 'minus')}>
										<UilMinus></UilMinus>
									</button>
									<button
										className="tg-products-button-add2 ripple"
										style={{
											'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
											'color': reduxInformation?.theme?.button_text_color
										}}
										onClick={() => handleChangeQuantity(item, 'plus')}>
										<UilPlus></UilPlus>
									</button>
								</div>
							}
						</div>
					</div>
				))}
			</div>

			{process.env.NODE_ENV === 'development' &&
				<button className="tg-main-button"
					style={{
						'backgroundColor': reduxInformation?.theme?.button_bg_color_plus,
						'color': reduxInformation?.theme?.button_text_color
					}}
					onClick={() => navigate(`/order/${login}/${posId}`)}>
					{t('continue')}: {getTotalPrice(reduxCart)}
				</button>
			}
		</div>
	)
}

export default Cart