import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Index from "./routes/index"
import { useTelegram } from "./pages/useTelegram"

import 'assets/css/style.css'
import 'assets/css/font.css'

function App() {
	const dispatch = useDispatch()
	const { tg } = useTelegram()

	useEffect(() => {
		tg.ready();
		tg.disableVerticalSwipes();

		document.addEventListener("wheel", function (event) {
			if (document.activeElement.type === "number") {
				document.activeElement.blur();
			}
		});
		document.addEventListener("keypress", function (event) {
			var invalidChars = ["+", "e"];
			if (document.activeElement.type === "number" && invalidChars.includes(event.key)) {
				event.preventDefault();
			}
		});
		return () => {
			//localStorage.clear('state')
			dispatch({ type: 'USER_LOGGED_OUT', payload: null })
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<Router>
				<Index />
			</Router>

			<ToastContainer
				position="top-left"
				autoClose={3000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick={false}
				theme="colored"
			/>
		</>
	)
}

export default App