const initialData = {
	'client': {},
	'menuName': '',
	'menu': '',
	'products': [],
	'theme': {},
}

const informationReducer = function (state = initialData, action) {
	switch (action.type) {
		case "SET_INFORMATION":
			return state = action.payload;
		case "SET_CLIENT":
			return { ...state, 'client': action.payload };
		case "SET_INFORMATION_THEME":
			return { ...state, 'theme': action.payload };
		case "SET_INFORMATION_INITIAL":
			return initialData;
		default:
			return state;
	}
};

export default informationReducer