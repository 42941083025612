import { combineReducers } from "redux"

import cart from "./cart/reducer"
import information from "./information/reducer"
import registration from "./registration/reducer"

const allReducers = combineReducers({
	cart,
	information,
	registration,
})

export const rootReducer = (state, action) => {
	// when a logout action is dispatched it will reset redux state
	if (action.type === 'USER_LOGGED_OUT') {
		state = undefined;
	}

	return allReducers(state, action);
};

export default rootReducer
