export function SET_ACCOUNT(payload) {
	return {
		type: 'SET_ACCOUNT',
		payload: payload
	};
}

export function SET_INFORMATION(payload) {
	return {
		type: 'SET_INFORMATION',
		payload: payload
	};
}

export function SET_INFORMATION_THEME(payload) {
	return {
		type: 'SET_INFORMATION_THEME',
		payload: payload
	};
}

export function SET_INFORMATION_INITIAL(payload) {
	return {
		type: 'SET_INFORMATION_INITIAL',
		payload: payload
	};
}

export function SET_CLIENT(payload) {
	return {
		type: 'SET_CLIENT',
		payload: payload
	};
}

export function SET_CART(payload) {
	return {
		type: 'SET_CART',
		payload: payload
	};
}

export function SET_REGISTRATION_INFO(payload) {
	return {
		type: 'SET_REGISTRATION_INFO',
		payload: payload
	};
}