import React from 'react'

import Check from '../pages/Check'
import Login from '../pages/Login'
import ActivationCode from '../pages/ActivationCode'
import Menu from '../pages/Menu'
import Products from '../pages/Products'
import Cart from '../pages/Cart'
import Order from '../pages/Order'

const publicRoutes = [
	{ path: "/check/:login/:posId", component: <Check /> },

	{ path: "/login/:login/:posId", component: <Login /> },
	{ path: "/activation-code/:login/:posId", component: <ActivationCode /> },
	{ path: "/menu/:login/:posId", component: <Menu /> },
	{ path: "/products/:login/:posId", component: <Products /> },
	{ path: "/cart/:login/:posId", component: <Cart /> },
	{ path: "/order/:login/:posId", component: <Order /> },
]

export { publicRoutes }